import MuiInputLabel, { InputLabelProps } from "@mui/material/InputLabel";
import { getStyleProps, passProps } from "modules/themes/styleUtils";

import MuiTextField, { TextFieldProps } from "@mui/material/TextField";

import React from "react";
import { Box, BoxProps } from "../box";

export const InputLabel = (props: InputLabelProps) => {
  const { children, ...rest } = props;
  return (
    <MuiInputLabel {...passProps(rest)} {...getStyleProps(rest)}>
      {children}
    </MuiInputLabel>
  );
};

export const Input = React.forwardRef((props: BoxProps, ref) => {
  return (
    <Box
      component="input"
      ref={ref}
      {...passProps(props)}
      {...getStyleProps({
        ...props,
        sx: {
          padding: "4px 8px",
          color: "rgba(0, 0, 0, 0.87)",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          borderRadius: "10px",
          ...(props.sx || {}),
          transition: "all .2s",
          "&:hover": {
            border: "1px solid rgb(99, 115, 129)",
            outline: "none",
          },
          "&:focus": {
            border: "1px solid rgb(64, 181, 181)",
            outline: "none",
          },
        },
      })}
    />
  );
});

export const Textarea = React.forwardRef((props: BoxProps, ref) => {
  return (
    <Box
      component="textarea"
      ref={ref}
      {...passProps(props)}
      {...getStyleProps({
        ...props,
        sx: {
          padding: "4px 8px",
          color: "rgba(0, 0, 0, 0.87)",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          borderRadius: "10px",
          ...(props.sx || {}),
          transition: "all .2s",
          "&:hover": {
            border: "1px solid rgb(99, 115, 129)",
            outline: "none",
          },
          "&:focus": {
            border: "1px solid rgb(64, 181, 181)",
            outline: "none",
          },
        },
      })}
    />
  );
});

export { TextFieldProps };

export const TextField = React.forwardRef((props: TextFieldProps, ref) => {
  const { native, ...rest } = props;

  return (
    <MuiTextField
      native={
        typeof native !== "undefined" ? (native ? "true" : "false") : undefined
      }
      ref={ref}
      {...passProps({ ...rest })}
      {...getStyleProps(rest)}
      InputProps={{
        ...(rest.InputProps || {}),
        sx: {
          ...(rest.InputProps?.sx || {}),
          fontSize: "14px",
        },
      }}
    />
  );
});

interface Validation {
  valid: boolean;
  message: string;
}

export const NumberInput = ({
  onChange,
  onValidate,
  value,
  restProps = {},
}: {
  onChange: (value: number) => void;
  value: number;
  restProps: Partial<TextFieldProps>;
  onValidate: (value: number) => Validation;
}) => {
  const [currentValue, setCurrentValue] = React.useState(value);

  const handdleChange = (event: React.ChangeEvent) => {
    const numberValue = parseInt(event.target.value);
    const validation = onValidate(numberValue);
    if (validation.valid) {
      onChange(numberValue);
    }
    setCurrentValue(numberValue);
  };

  return (
    <TextField
      color={onValidate(currentValue).valid ? undefined : "error"}
      value={currentValue}
      type="number"
      onChange={handdleChange}
      {...restProps}
      InputProps={{
        ...(restProps.InputProps || {}),
        sx: { borderRadius: "10px", ...(restProps.InputProps?.sx || {}) },
      }}
    />
  );
};
